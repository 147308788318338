import { useStaticQuery, graphql } from "gatsby" 
import React, {useState, useEffect } from "react" 
import PropTypes from "prop-types" 
import Header from "./Header"   
import Footer from "./Footer"
import { TopImage } from "./TopImage"
import { ChevronUpIcon } from "@heroicons/react/solid"

const Layout = ({ children,location, pageContext }) => {
  const data = useStaticQuery(graphql`
  query Pages {
    scomos {
      pageFindAll(menu:"main") {
        id
        parent 
        menuName
        menu
        url
        lft
      }
    }
  }
`)

  const menu = [
    { id:'35', menuName: 'Pro oprávněné', url: 'pro-opravnene/', children: null },
    { id:'34', menuName: 'Pro povinné', url: 'jak-postupovat/', children: null },
    { id:'10', menuName: 'Dražby', url: 'drazby', children: null },
    { id:'33', menuName: 'Další činnost', url: 'dalsi-cinnost/', children: null },
  ]

  const topMenu = [
    { id:'26', menuName: 'Úřední deska', url: 'uredni-deska/', children: null },
    { id:'32', menuName: 'Vzory podání', url: 'vzory-podani/', children: null },
    { id:'5', menuName: 'Legislativa', url: 'legislativa/', children: null },
    { id:'8', menuName: 'Média a zprávy', url: 'media-zpravy/', children: null },
    { id:'20', menuName: 'O úřadu', url: 'o-nas/', children: null },
  ]
 
  const [ pages, setPages ] = useState([]); 
  const [headerClassName, setHeaderClassName] = useState('');
  const [showGoTop, setGoTop] = useState('hidden');

  useEffect(() => {  
    let items = []; 
    let mainId = 0;
    let parentChildren = [];
    let currentChildren = [];
    let currentId = pageContext?.pageId;
    let parentId = pageContext?.parent;

    function menuItems() { 
        data.scomos.pageFindAll.forEach((menuItem) => {
          if(menuItem.parent==='0') { 
            mainId = menuItem.id
          }
          items[menuItem.id] = menuItem
        })
  
        let newItems = [...items]
        items.forEach((menuItem) => {
          if(items[menuItem.parent]) {
            if(!items[menuItem.parent]['children']) {
              newItems[menuItem.parent]['children'] = [];
            }
            newItems[menuItem.parent]['children'][menuItem.lft] = menuItem
          }          
        })
 
        newItems.forEach((menuItem) => { 
          if(menuItem.parent === parentId && newItems[menuItem.parent]['children']) {
            parentChildren = newItems[menuItem.parent]['children']
          }
          if(menuItem.id === currentId && newItems[menuItem.id]['children']) {
            currentChildren = newItems[menuItem.id]['children']
          }
        })

        let sorted = [] 
        newItems[mainId]['children'].forEach((newMenuItem) => {
          sorted[newMenuItem.lft] = newMenuItem 
        }); 
  
        return {
          pages: sorted,
          mainId: mainId,
          currentId: currentId,
          parentId: parentId,
          parentChildren: parentChildren,
          currentChildren: currentChildren,
        };
      }
      let newMenu = menuItems();
      setPages(newMenu); 
    // eslint-disable-next-line
  }, []); 
 
  const handleScrollUp = () => {
    window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
  }

  
  const handleScroll = () => { 
    if (window.pageYOffset >= 10) {
        setHeaderClassName('bg-white backdrop-blur-md backdrop-filter bg-opacity-50 shadow-md');
    } else {
        setHeaderClassName('');
    }
    if (window.pageYOffset >= 300) {
        setGoTop('');
    } else {
        setGoTop('hidden');
    }
}


  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value


  return (
    <>
     {/* {location?.pathname !== '/' && <>
      <div className="h-144 overflow-hidden w-full fixed top-0 ">
        <StaticImage src={'../images/gallery/06.jpg'} className=" object-cover w-full h-full" />
        <div className="h-72  w-full absolute bottom-0 bg-gradient-to-t from-white to-transparent "></div> 
      </div>
        </>
    } */} 
    <Header menu={menu} topMenu={topMenu} pages={pages} headerClassName={headerClassName} /> 
    
      <TopImage location={location} pageContext={pageContext}  pages={pages} />
 
    <div className="relative pb-10 shadow-md">

      <main  >
        {children}
      </main>
        
    </div>
      <Footer />
      
      <div className={"bg-gray-600 fixed bottom-5 right-5 rounded-full z-50 " + showGoTop} >
        <button type={'button'} onClick={ handleScrollUp } className="p-3" >
          <ChevronUpIcon className="w-8 h-8 text-white" />
        </button>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
