
import React, {useRef, useEffect} from "react"
import { Link } from "gatsby" 
import CookieConsent from "../elements/CookieConsent"; 
import { ClockIcon, LocationMarkerIcon, PhoneIcon } from "@heroicons/react/outline";
 
  export default function Footer() { 

    const consentRef = useRef();

    useEffect(() => {
      consentRef.current.setAttribute("data-cc","c-settings") 
      // inputRef.current.setAttribute('de-di-var', '');
    }, []);

      return (
          <>
        <footer className="bg-gray-700  text-gray-100" aria-labelledby="footerHeading">
            <h2 id="footerHeading" className="sr-only">
                Patička
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="pb-8 grid lg:grid-cols-6  gap-10 border-t border-gray-500 border-opacity-50 pt-8">
                  <div className="col-span-2 flex space-x-4">
                    <LocationMarkerIcon className="w-8 h-8 opacity-30" />
                    <div>
                      <h3 className=" font-bold  tracking-wider ">Exekutorský úřad Praha 9</h3>
                        <p className="text-base  ">Pod Pekárnami 245/10 <br />190 00 Praha 9 - Vysočany</p> 
                      <div className="text-base mt-3 ">ID datové schránky: g2crz9v</div> 
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="flex space-x-4">
                      <ClockIcon className="w-8 h-8 opacity-30" />
                      <div>
                      <div className="text-base "><div className="font-bold">Úřední hodiny</div>
                      <table className="table-auto">
                        <tbody>
                        <tr>
                          <td className="text-right">
                            Po - Čt:
                          </td>
                          <td className="px-5">
                            8:00 - 12:00
                          </td>
                          <td className="pl-2">
                            12:30 - 15:00
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">Pá:</td>
                          <td className="px-5">8:00 - 12:00</td>
                          <td className="pl-2 text-center opacity-50">
                            –
                          </td>
                        </tr>
                        </tbody>
                      </table>
                        
                      
                      </div> 

                      </div>
                    </div>
                      
                  </div>
                  <div className="  mt-10 lg:mt-0 col-span-2 flex space-x-4">
                      <PhoneIcon className="w-8 h-8 opacity-30" />
                      <div>
                        <p className="text-base ">+420 603 142 152</p>
                        <p className="text-base ">+420 739 002 457</p>
                        <p className="mt-3"><a href={'mailto:suchanek@exekucepraha.cz'} className="text-base ">suchanek@exekucepraha.cz</a> </p>
                        <div className="mt-3">
                          <a href={'mailto:e-podatelna@exekucepraha.cz'} className="text-base ">e-podatelna@exekucepraha.cz</a> 
                          <div className="text-xs">
                            elektronické podání
                          </div>
                        </div>

                      </div>
                      
                    
                  </div>
                </div>
                
                <div className="mt-8 border-t border-gray-500 border-opacity-50 pt-8 md:flex md:items-center md:justify-between">
                <div className="flex space-x-6 md:order-2">
                    {/* {navigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true" /> 
                        
                    </a>
                    ))} */}
                </div>
                <p className="mt-8 text-sm text-center   opacity-70 md:mt-0  w-full grid lg:flex justify-center">
                    <span>&copy; {new Date().getFullYear()} </span>

                    <Link to={'/gdpr'} className="ml-5    underline hover:no-underline">Zásady ochrany osobních údajů GDPR</Link> 
                    <button type="button" ref={consentRef} data-cc="c-settings" className="lg:ml-5   underline hover:no-underline">Nastavení cookies</button>
                </p>
                </div>
            </div>
            </footer>
            <CookieConsent  />
          </>
      )
  }
