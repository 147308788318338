import { PhoneIcon } from "@heroicons/react/solid";
import { Link } from "gatsby" 
import React, {useState } from "react"
import {default as Logo} from '../../images/logo.svg'; 
import MainMenu from "./MainMenu";

export default function Header({menu, topMenu,white, pages, headerClassName}) { 
  const [open, setOpen] = useState(false);
   

  return (
  <> 
  
    <div className="hidden lg:block bg-brown p-1 text-white  text-xs  ">
      <div className="flex items-center justify-end text-xs px-5">
        {topMenu.map((page, i) => (
            <div className="relative  h-full " key={i}>
              <div className=" flex h-full ">
                <Link to={'/' + page.url} partiallyActive={page.url==='' ? (false) : (true)} 
                  className={"group inline-flex items-center  focus:outline-none   px-3 py-2  h-full mr-1  hover:underline  w-full " + (pages.parentId === page.id && "font-bold") }
                  activeClassName={" font-bold " }
                  >
                  <span>{page.menuName}</span>
                  
                  </Link>
              </div>
            </div>
          ))} 

      </div>
      </div> 
  {/* <div className=" bg-gradient-to-b from-black to-transparent opacity-70 h-60 z-30 w-full fixed top-0">

  </div> */}
  <div className={"sticky h-20  z-50 w-full top-0 transition-all ease-in-out duration-300   flex items-center    " + headerClassName }>
    
    <div className=" h-full flex items-center w-full ">
      
      <div className="flex justify-between items-center w-full  lg:justify-start py-2 lg:py-0 ">
        
        <div className={"order-2 lg:order-1 flex items-center h-full text-black lg:w-2/5 px-5 " }>
            <MainMenu open={open} setOpen={setOpen} pages={pages} menu={menu} topMenu={topMenu} white={white} />

        </div>
          <Link to={'/'} className="text-white lg:w-1/5 order-1 lg:order-2 px-5 lg:px-0 flex items-center justify-center " >
            <img src={Logo} className={"transition-all ease-in-out duration-150 " + (headerClassName ? "h-10 lg:h-12" : "h-16 lg:h-20 ")} alt="Logo" /> 
          </Link>
        <div className="hidden lg:order-3 lg:flex items-center lg:w-2/5 justify-end">
          <div className="lg:order-2 flex justify-end lg:flex-1 px-10">
             <div className="space-x-4 flex items-center">
                <div className="text-sm">
                  <div className="flex items-center space-x-2 font-semibold">
                      <PhoneIcon className="w-4 h-4" /><a href="tel:+420603142152"><span>603 142 152</span></a>
                  </div>
                  <div className="flex items-center space-x-2 font-semibold">
                      <PhoneIcon className="w-4 h-4" /><a href="tel:+420739002457"><span>739 002 457</span></a>
                  </div>
                </div>
                <Link to={'/kontakty'} className="p-1 font-semibold text-brown px-5 border-brown border-2 hover:bg-brown hover:bg-opacity-10" >
                  Kontakty
                </Link>
              </div>

          </div>
        </div>
         
      </div>
    </div> 
      
  </div> 
  </>
  )
}

