import React, { useEffect } from 'react';
import { useInView } from "react-intersection-observer"

export const Animate = ({ threshold = 0.15, triggerOnce = true, delay=2000, distance = 100, ...remainingProps }) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })
   
  let delayValue
  switch(delay) {
    case 1:
      delayValue = "delay-[500ms]"
      break;
    case 2:
      delayValue = "delay-[700ms]"
      break;
    case 3:
      delayValue = "delay-[1000ms]"
      break;
    default:
      delayValue = "delay-[0ms]"
  }
   
  return (
    <div
      ref={ref}
      className={"transition ease-in-out duration-[1000ms]  " + delayValue}
      style={{
        // adjust these as desired 
        opacity: inView ? 1 : 0, 
        transform: `translateY(${inView ? 0 : distance}px)`,
      }}
      {...remainingProps}
    />
  )
}