import React from "react"
import { default as HomeImage } from "../../images/stul-znak.svg"
import { default as Kladivko } from "../../images/soudni-razitko-velke.svg"
import { Link } from "gatsby"
import { Animate } from "../elements/Animate"
import { Submenu } from "./Submenu"

export const TopImage = ({location, pageContext, pages}) => {
     

    switch (location.pathname) {
        case '/':
          return (
            <>
              <div className="relative pt-20 lg:pt-32 bg-amber-700 bg-opacity-30 py-10 -mt-20 transition-all ease-in-out duration-300  ">
                <div className="grid lg:grid-cols-2 max-w-6xl mx-auto">
                  <div className="p-10 space-y-4">
                    <Animate distance={50} delay={0}>
                      <div className="text-2xl lg:text-4xl font-bold leading-tight">
                        Spolehlivý partner pro exekuční vymáhání pohledávek 
                      </div>
                      <div className="font-semibold lg:text-xl">Mgr. Michal Suchánek, soudní exekutor</div>
                    </Animate>
                    <Animate distance={50} delay={1}>
                      <div className="font-normal lg:text-xl">Exekutorský úřad Praha 9 poskytuje právní pomoc a další činnosti dle exekučního řádu.</div>
                      <Link to={'/kontakty'}><button className="my-3 p-3 px-5 bg-brown text-white rounded-md hover:bg-opacity-80">Kontaktujte nás</button></Link>
                    </Animate>
                  </div>
                  <div className="p-10">
                    <img src={HomeImage} alt="Exekutorský úřad" className="" />
                  </div>
                </div>
              </div>
            </>
          )
        case 'drazby':
          return (
              <>
              </>
          )
        default:
          return (
            <>
            <div className="relative pt-20 lg:pt-32 bg-amber-700 bg-opacity-30 py-10 -mt-20 transition-all ease-in-out duration-300  ">
              <div className="flex justify-between max-w-6xl mx-auto">
                <div className="p-7 pl-10 pr-0 space-y-4">
                    <div className="text-2xl lg:text-4xl font-bold leading-tight">
                      <Animate distance={30} delay={0}>
                        {pageContext?.title}
                      </Animate>
                    </div> 
                </div>
                <div className="px-10 pl-5">
                  <Animate distance={10} delay={1}>
                    <img src={Kladivko} alt="Exekutorský úřad" className=" w-24 lg:w-32" />
                  </Animate>
                </div>
              </div>
            </div>
           
                <Submenu pages={pages} /> 
              
          </>
          )
      }
}