/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */


 import React, { Fragment} from "react" 
 import { Transition, Popover } from '@headlessui/react' 
 import MainMenuItem from "./MainMenuItem"
 import {default as Logo} from '../../images/logo.svg';
import { MenuIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";


 const MainMenu = ({open, menu, pages, topMenu, white}) => {
  
 
   return (
     <>
      <nav className="hidden lg:flex tracking-tight h-full"> 
          {menu.map((page, i) => (
            <MainMenuItem item={page} pages={pages} key={i} white={white} /> 
        ))}

      </nav>
       <Popover className=" lg:hidden">
           
          <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="lg:hidden lg:w-0 mr-2 p-2 inline-flex items-center justify-center   hover:bg-gray-100 focus:outline-none ">
                  <span className="sr-only">Otevřít menu</span>
                  <MenuIcon className={"block h-8 w-8 "  + (white ? "text-white" : "text-black")} aria-hidden="true" />
                </Popover.Button>
              </div>
 
          
              <Transition.Root as={Fragment} open={open}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                    >
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                       
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                              <div>
                                <img src={Logo} className="h-10  " alt="Logo" />
                              </div>
                              <div className="-mr-2">
                                <Popover.Button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                                  <span className="sr-only">Zavřít menu</span>
                                  {/* <!-- Heroicon name: outline/x --> */}
                                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </Popover.Button>
                              </div>
                            </div>
                            <div className="mt-6">
                              <nav className="grid ">
                                {menu.map((page, i) => (
                                  <MainMenuItem item={page} key={i} /> 
                                ))}
                                {topMenu.map((page, i) => (
                                  <MainMenuItem item={page} key={i} /> 
                                ))}
                                <Link to={'/kontakty'} className={"group border-b-2 border-transparent inline-flex items-center text-sm   focus:outline-none   px-3 py-3  h-full mr-1  hover:border-white  w-full" } >
                                  Kontakty
                                </Link>
                              </nav>
                            </div>
                          </div>  
                         
                      </div>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
      
       </Popover>
     </>
   )
 }
  
 export default MainMenu
 