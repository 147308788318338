import { Link } from "gatsby";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Submenu = ({ pages }) => {
  let items = [];
  if(pages.parentChildren && pages.parentId !== pages.mainId) {
      items = pages.parentChildren
  }

  if(items.length <= 0) {
    return <></>;
  }

  return (
    <div className="relative py-10 ">
    <div className="max-w-6xl mx-auto">
    <div className="px-10">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {items.map((item) => (
            <Link
              key={item.menuName}
              to={'/' + item.url}
              className={classNames(
                item.id === pages.currentId
                  ? "border-brown text-brown"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "  py-4 px-5 lg:px-10 text-center border-b-2 font-medium text-sm"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.menuName}
            </Link>
          ))}
        </nav>
      </div>
    </div>
    </div>
    </div>
  );
};
